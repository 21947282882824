import $ from "jquery";
import {domReady} from "@local/ts/lib/Utility";

let words_array = ["mobile app", "marketing", "branding", "web design"],
    current_word = 0;

function changeTextFade() {
    $("#marquee-cycle").animate({
        opacity: 0
    }, function () {
        $("#marquee-cycle").text(words_array[current_word]).animate({opacity: 1});
        current_word++;
    });

    if (current_word === words_array.length) {
        current_word = 0;
    }
}

// UTILITY FUNCTIONS ***********************************************************
window.debounce = function (func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

// SCROLL BEHAVIOR **************************************************************
window.doTheScroll = function () {
    let state = window.scrollY,
        element = document.getElementsByTagName("header")[0],
        height = element.offsetHeight - 60;

    state < height
        ? document.getElementById("scroll-to-top").classList.add("d-none")
        : document.getElementById("scroll-to-top").classList.remove("d-none");

    lastScrollTop = state <= 0 ? 0 : state; // For Mobile or negative scrolling
};

let lastScrollTop = 0;
window.addEventListener("scroll", debounce(doTheScroll, 20), false);

window.pageTop = function () {
    let element = document.querySelector('body');

    element.scrollIntoView();
};

window.scrollToID = function (id) {
    let element = document.getElementById(id);

    if (!element) return;

    element.scrollIntoView();
};

// Force Page Scroll based on Hash
window.scrollToHash = () => {
    if (window.location.hash) {
        let hash_id = window.location.hash.replace('#', '');
        window.scrollToID(hash_id);
    }
};
window.addEventListener('load',() => {
    window.scrollToHash();
    setInterval(changeTextFade, 3500);
});


// Navigation
const updateVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};
const isMobile = () => {
    return window.innerWidth <= 991;
};

let dropdown_menu = document.querySelectorAll("[data-dropdown-toggle]");
let hamburger_button = document.querySelector("[data-toggles*='main-navigation']");

["resize", "deviceorientation"].forEach((event_type) => {
    window.addEventListener(event_type, () => {
        if (!isMobile()) {
            let mainNav = document.querySelector('[data-toggled-panels*="main-navigation"]');

            if (mainNav) {
                hamburger_button.dispatchEvent(new Event("click"));
                dropdown_menu.forEach(dropdown_element => {
                    dropdown_element.classList.remove('show');
                });
            }
        }

        updateVH();
    });
});

if (dropdown_menu) {
    dropdown_menu.forEach(element => {
        const first_link = element.querySelector('.dropdown-state');

       first_link.addEventListener('click', (event) => {
           if (!isMobile()) return;

           event.preventDefault();

           dropdown_menu.forEach(dropdown_element => {
               if (dropdown_element !== element) {
                   dropdown_element.classList.remove('show');
               }
           });

           element.classList.toggle('show');
       });
    });
}

if (hamburger_button) {
    hamburger_button.addEventListener('click', (event) => {
        if (!isMobile()) return;

        let menu = document.querySelector('[data-menu="main-navigation"]');

        if (menu.dataset.toggled === 'false') {
            dropdown_menu.forEach(dropdown_element => {
                dropdown_element.classList.remove('show');
            });
        }
    });
}