// css
import "@local/scss/main.scss";

// from npm
import {$} from '@local/ts/lib/patchedJQuery';
import JSShare from 'js-share';

// raw js
import '@local/ts/rawJS/script.js';
import '@local/ts/rawJS/form-validation.js';
import '@local/ts/rawJS/hover-tab.js';
import '@local/ts/rawJS/slideshows.js';

// included ts file requirements
import {domReady, windowReady} from "@local/ts/lib/Utility";
import {register as registerParticles} from '@local/ts/lib/tsParticles';
import * as CSSVars from "@local/ts/interactions/JSProvidedCSSVars";
import * as ElementToggleInteractions from "@local/ts/interactions/ElementToggleInteractions";
import {autoloadRecaptcha, injectScript} from "@local/ts/lib/ScriptInjector";
import {isFastConnection} from "@local/ts/lib/deviceSpecs";
import {isRoute} from "@local/ts/lib/pageDetails";

import GLightbox from "glightbox";
window.GLightbox = GLightbox;

// import the whole file, where exported things are properties of a value
import { register as registerGLightbox } from '@local/ts/interactions/GLightboxInteractions';

/* type declarations **********************************************************/
/******************************************************************************/
window.jQuery = window.$ = $;
window.JSShare = JSShare;

declare global {
  interface Window {
    scrollToHash: () => void;
    jQuery: () => void;
    $: () => void;
    JSShare: () => void;
  }
}

/* run commands on dom ready **************************************************/
/******************************************************************************/

domReady(() => {
  CSSVars.register();
  ElementToggleInteractions.register();
  registerGLightbox();
  autoloadRecaptcha('script_src_recaptcha');

  let slide_selectors = [
    '.slideshow-work'
  ];

  slide_selectors.forEach(selector => {
    let sliders = $(selector);

    sliders.each(function () {
      $(this).on('setPosition', function () {
        if (typeof window.scrollToHash === 'function') {
          window.scrollToHash();
        }
      });
    });
  });
});

windowReady(async () => {
  document.querySelector('body').classList.remove('no-animations');
  document.querySelectorAll('.hide-until-load').forEach((el) => {
    el.classList.remove('hide-until-load');
  });

  document.querySelectorAll('[data-showonload]').forEach(element => {
    element.classList.remove('d-none');
  });

  if (isFastConnection() && isRoute('/')) {
    injectScript(
      'tsParticles',
      '/proxy.php?url=https://cdn.jsdelivr.net/npm/tsparticles@2.8.0/tsparticles.bundle.min.js',
      {
        'integrity': 'sha256-4brD2EfP4eiCQhJ6+OM+ul5/EwDOcuU+vCF8NXp0ZZg=',
        'crossorigin': 'anonymous',
        'defer': 'defer',
        'async': 'async',
      }
    ).then(() => {
      registerParticles();
    });
  }
});