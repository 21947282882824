// VALIDATION LIBRARY **********************************************************
(() => {
  "use strict";
  const lib = {};

  /**
   * an object that contains named validators, which return true if valid or
   * false otherwise
   */
  lib.validators = {
    "not-empty": ($element) => {
      return $element.value.length > 0;
    },
    email: ($element) => {
      return $element.value.match(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      );
    },
    min: ($element, min_length) => {
      return $element.value.length >= min_length;
    },
    max: ($element, max_length) => {
      return $element.value.length <= max_length;
    },
  };

  /**
   * checks if a validator name exists within `lib.validators`
   * @param name the validator name to check
   * @returns {*|boolean}
   */
  lib.validatorExists = (name) => {
    return lib.validators[name] && typeof lib.validators[name] === "function";
  };

  /**
   * validates all elements that have data-validate-with attribute against
   * validators that match the space-separated names the attribute contains.
   * Some validators also accept inputs by using validator:value format:
   *
   * ex: <input data-validate-with="not-empty email min:2"/>
   *
   * @param $parent the form element you wish to validate children for
   */
  lib.validate = ($parent) => {
    // $parent could be an event...
    if ($parent.currentTarget) {
      $parent.preventDefault();
      $parent.stopPropagation();
      $parent = $parent.currentTarget;
    }

    // run the validation process
    let violations = [];
    $parent.querySelectorAll("[data-validate-with]").forEach(($element) => {
      const validators = $element.dataset.validateWith.split(" ");

      let validator_name;
      let validator_input;
      let invalid_tests = [];

      validators.forEach((validator) => {
        if (validator.includes(":")) {
          const validator_name_parts = validator.split(":");
          validator_name = validator_name_parts[0];
          validator_input = validator_name_parts[1];
        } else {
          validator_name = validator;
          validator_input = null;
        }

        if (lib.validatorExists(validator_name)) {
          const is_valid = lib.validators[validator_name](
            $element,
            validator_input
          );
          if (!is_valid) {
            invalid_tests.push(validator_name);
          }
        }
      });

      if (invalid_tests.length > 0) {
        violations.push({
          element: $element,
          validators: $element.dataset.validateWith,
          failed: invalid_tests.join(" "),
        });
      }

      $element.tagName;
      $element.classList.remove("is-invalid");

      if (invalid_tests.length > 0) {
        $element.classList.add("is-invalid");
        $element.removeAttribute("data-validated");
        $element.dataset.invalid = invalid_tests.join(" ");

        // scroll to and focus the element
        const $first_invalid_element = $parent.querySelector("[data-invalid]");
        $first_invalid_element.focus({ preventScroll: false });
      } else {
        $element.dataset.validated = "true";
      }
    });

    return violations;
  };

  window.validation = lib;
})();


// FORM VALIDATION ***************************************************************
function validateForm(simp_cont) {
    let name = simp_cont.name.value;
    let email = simp_cont.email.value;
    let phone = simp_cont.phone.value;

    if (name === "" || email === "" || phone === "") {
        if (email === "") {
            simp_cont.email.focus();
            simp_cont.email.style.border = '1px solid #e8544f';
            simp_cont.email.style.color = '#e8544f';
            simp_cont.email.placeholder = 'Please provide email';
        } else {
            simp_cont.email.style.border = '1px solid #555';
            simp_cont.name.style.color = '#222';
        }

        if (phone === "") {
            simp_cont.phone.focus();
            simp_cont.phone.style.border = '1px solid #e8544f';
            simp_cont.phone.style.color = '#e8544f';
            simp_cont.phone.placeholder = 'Please provide phone';
        } else {
            simp_cont.phone.style.border = '1px solid #555';
            simp_cont.name.style.color = '#222';
        }

        if (name === "") {
            simp_cont.name.focus();
            simp_cont.name.style.border = '1px solid #e8544f';
            simp_cont.name.style.color = '#e8544f';
            simp_cont.name.placeholder = 'Please provide name';
        } else {
            simp_cont.name.style.border = '1px solid #555';
            simp_cont.name.style.color = '#222';
        }
        return false;
    } else {
        return true;
    }
}

// INPUT MASKS *****************************************************************
// iMask script is required for masks to function.
const mask_routines = {
    phone: ($element) => {
        IMask($element, {
            mask: [
                {
                    mask: "(000) 000-0000"
                },
                {
                    mask: "+{0} (000) 000-0000"
                },
                {
                    mask: "+{00} (000) 000-0000"
                },
                {
                    mask: "+{000} (000) 000-0000"
                }
            ],
            dispatch: function (appended, dynamicMasked) {
                const number = (dynamicMasked.value + appended).replace(/\D/g, "");

                if (number.length <= 10) {
                    return dynamicMasked.compiledMasks[0];
                }
                if (number.length === 11) {
                    return dynamicMasked.compiledMasks[1];
                }
                if (number.length === 12) {
                    return dynamicMasked.compiledMasks[2];
                }

                return dynamicMasked.compiledMasks[3];
            }
        });
    },
};

const mask = () => {
    if (typeof IMask === "undefined") {
        return;
    }
    document.querySelectorAll("[data-input-mask]").forEach(($element) => {
        const mask_requested = $element.dataset.inputMask;

        if (typeof mask_routines[mask_requested] === "function") {
            mask_routines[mask_requested]($element);
        }
        delete $element.dataset.mask;
    });
};

// INIT ************************************************************************
// js input masking
mask();