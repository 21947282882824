const register = async () => {

    let tsParticles = window["tsParticles"];
    if (!tsParticles) {
        return;
    }

    let i = 0;
    document.querySelectorAll(".confetti").forEach((element:any) => {
      tsParticles.set(`confetti_${i++}`, element, {
        style: {
          // @ts-ignore
          "background-color": "transparent",
          "position": "static"
        },
        fpsLimit: 90,
        emitters: {
          startCount: 0,
          position: {
            x: 50,
            y: 50
          },
          size: {
            width: 0,
            height: 0
          },
          rate: {
            delay: 0,
            quantity: 0
          },
          life: {
            duration: 0.1,
            count: 1
          },
        },
        interactivity: {
          detectsOn: "canvas",
          events: {
            resize: true
          }
        },
        particles: {
          color: {
            value: [
              "#CD9A3D",
              "#EFDCA2",
              "#C2C3C4"
            ]
          },
          move: {
            direction: "bottom",
            enable: true,
            outModes: {
              default: "out"
            },
            size: true,
            speed: {
              min: 1,
              max: 3
            }
          },
          number: {
            value: 180,
            density: {
              enable: true,
              area: 640
            }
          },
          opacity: {
            value: 1,
            animation: {
              enable: false,
              startValue: "max",
              destroy: "min",
              speed: 1,
              sync: true
            }
          },
          rotate: {
            value: {
              min: 0,
              max: 360
            },
            direction: "random",
            move: true,
            animation: {
              enable: true,
              speed: 60
            }
          },
          tilt: {
            direction: "random",
            enable: true,
            move: true,
            value: {
              min: 0,
              max: 360
            },
            animation: {
              enable: true,
              speed: 60
            }
          },
          shape: {
            type: [
              "circle",
              "square",
              "image",
            ],
            options: {
              image: [
                {
                  "src": "/bundle/images/themes/anniversary/confetti-3.svg",
                  "width": 11.56,
                  "height": 15.8,
                  "particles": {
                    "size": {
                      "value": 2
                    }
                  }
                },
                {
                  "src": "/bundle/images/themes/anniversary/confetti-4.svg",
                  "width": 18.58,
                  "height": 20.83,
                  "particles": {
                    "size": {
                      "value": 6
                    }
                  }
                },
                {
                  "src": "/bundle/images/themes/anniversary/confetti-5.svg",
                  "width": 9.36,
                  "height": 10.15,
                  "particles": {
                    "size": {
                      "value": 7
                    }
                  }
                },
                {
                  "src": "/bundle/images/themes/anniversary/confetti-7.svg",
                  "width": 18.05,
                  "height": 8.87,
                  "particles": {
                    "size": {
                      "value": 4
                    }
                  }
                }
              ]
            }
          },
          size: {
            value: {
              min: 2,
              max: 4
            }
          },
          roll: {
            darken: {
              enable: true,
              value: 30
            },
            enlighten: {
              enable: true,
              value: 30
            },
            enable: true,
            speed: {
              min: 15,
              max: 25
            }
          },
          wobble: {
            distance: 30,
            enable: true,
            move: true,
            speed: {
              min: -15,
              max: 15
            }
          }
        },
        smooth: true,
        detectRetina: true
      }).then(() => {
        // success
      });
    });
}

export { register };