import { throttle } from "../lib/Utility";

/**
 * updates the --vh css variable
 */
const updateVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

/**
 * updates the --scrollbar-width css variable
 */
const updateScrollbarWidth = () => {
  document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
}
/**
 * provides the following css variables:
 * --scrollbar-width : real-time width of the scrollbar
 * --vh : real-time height of the viewport
 */
const register = (): void => {
  updateVH();
  updateScrollbarWidth();

  window.addEventListener(
    "resize",
    throttle(() => {
      // We execute the same script as before
      updateVH();
      updateScrollbarWidth();
    })
  );
};

export { register, updateVH, updateScrollbarWidth };
