interface EventCallback {
  (evt?: Event): void;
}

/**
 * tames a rapidly firing function to only fire at the end of its call attempts
 * @param func the function you want to run
 */
function debounce(func: {
  (): void;
  (): void;
  (args_0: Event): void;
}): (event: Event) => void {
  let timer: string | number | NodeJS.Timeout;
  return function (event: Event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 100, event);
  };
}

/**
 * throttles a function to only fire every <wait> time at most.
 * @param func the function you want to run
 * @param wait the time in ms you want to wait
 */
function throttle(
  func: { (): void; apply: CallableFunction },
  wait = 300
): (this: Window, ev: Event) => void {
  let inThrottle: boolean,
    lastFn: ReturnType<typeof setTimeout>,
    lastTime: number;
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, ...args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          func.apply(this, ...args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
}

/**
 * calls a function when the dom is ready
 * @param callback the function to call
 */
const domReady = (callback: EventCallback): void => {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    callback();
  }
};

/**
 * calls a function when all window content is loaded
 * @param callback the function to call
 */
const windowReady = (callback: EventCallback): void => {
  if (document.readyState === "complete") {
    callback();
  } else {
    window.addEventListener("load", callback);
  }
};

export { debounce, throttle, windowReady, domReady };
