
type ScriptAttributes = {
  [key: string]: any;
}

declare global {
  interface Window {
    script_src_recaptcha: string;
  }
}

/**
 * Injects a script into the head of the document. Names are unique, and scripts
 * with the same name will not be injected twice.
 * @param name
 * @param src
 * @param attributes
 */
const injectScript = async (name: string, src: string, attributes: ScriptAttributes = {}) => {
  return new Promise<void>((resolve, reject) => {
    // check if script already exists
    const scriptExists = document.querySelectorAll(`script[data-name="${name}"]`).length > 0;
    if (scriptExists) {
      reject('Script already exists');
    }

    setTimeout(() => { // forces the script to load after load event
      // create script element
      const script = document.createElement('script');
      script.dataset.name = name;
      script.src = src;

      // add attributes
      Object.keys(attributes).forEach(key => {
        script.setAttribute(key, attributes[key]);
      });

      // add onload
      script.onload = () => {
        resolve();
      }

      // append script to the head
      document.querySelectorAll('head')[0].appendChild(script);
    },1);
  });
}

/**
 * Auto-loads the recaptcha script if any form element gets focused
 */
const autoloadRecaptcha = (global_variable_name:string) => {

  if (!window[global_variable_name]) {
    return;
  }

  const recaptchaInjectListener = async () => {
    await injectScript(
      'recaptcha',
      window[global_variable_name],
    );

    // remove the listener from all forms
    document.querySelectorAll('form').forEach(form => {
      form.removeEventListener('focusin', recaptchaInjectListener);
    });
  };

  document.querySelectorAll('form').forEach(form => {
    // detect if form gets focus
    form.addEventListener('focusin', recaptchaInjectListener);
  });
}

export { injectScript, autoloadRecaptcha }