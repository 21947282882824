
const hasAmpleBandwidth = () => {
  // assume fast connection for unsupported devices
  if (
    !navigator['connection'] ||
    !navigator['connection']['effectiveType']
  ) {
    return true;
  }

  // use effectiveType to determine if connection is fast
  const effectiveType = navigator['connection']['effectiveType'];
  switch(effectiveType) {
    case 'slow-2g':
    case '2g':
    case '3g':
      return false;
    default:
      return true;
  }
}

const hasAmpleMemory = () => {
  // assume it does for unsupported devices
  if (!navigator['deviceMemory']) {
    return true;
  }

  // use deviceMemory to determine if device has ample memory
  const deviceMemory = navigator['deviceMemory'];
  return deviceMemory > 1;
}

const hasAmpleCores = () => {
  // assume it does for unsupported devices
  if (!navigator['hardwareConcurrency']) {
    return true;
  }

  // use hardwareConcurrency to determine if device has ample cores
  const hardwareConcurrency = navigator['hardwareConcurrency'];
  return hardwareConcurrency > 1;
}

const hasAmpleRoundTripTime = () => {
  // assume it does for unsupported devices
  if (
    !navigator['connection'] ||
    !navigator['connection']['rtt']
  ) {
    return true;
  }

  // use rtt to determine if device has ample round trip time
  const rtt = navigator['connection']['rtt'];
  return rtt < 120;
}

const isFastConnection = () => {
  // console.log(hasAmpleBandwidth(), hasAmpleMemory(), hasAmpleCores(), hasAmpleRoundTripTime());
  return hasAmpleBandwidth() && hasAmpleMemory() && hasAmpleCores() && hasAmpleRoundTripTime();
}

export { hasAmpleBandwidth, hasAmpleMemory, hasAmpleCores, hasAmpleRoundTripTime, isFastConnection };